import React from 'react';
import AboutNavBar from '../quotes/AboutNavBar';
import AboutBackground from './AboutBackground';
import {FaFacebook,FaLinkedin,FaTwitter,FaInstagram,FaUserAlt} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Footer from './Footer';


const AboutPage = () => {
  return (
   <section>
    <div className='about-bg'>
        <AboutNavBar />
        <AboutBackground />
    </div>
 <div className="container mt-5 mb-5 bg-body shadow-lg rounded p-3">
      <h2>About Us</h2>
      <p>Welcome to Kak Quote, your source of daily inspiration through quotes. Our mission is to provide you with motivational and thought-provoking content to uplift your spirits and brighten your day.</p>
      <p>Our team of dedicated writers and designers work tirelessly to curate and create the best quotes that resonate with you. We believe that words have the power to inspire, transform, and bring positivity to your life.</p>
      <p>Thank you for being a part of our community, and we hope you enjoy your journey with Kak Quote.</p>
      <br />
      <hr />
      <h3>Meet Our Team</h3>
      <div className="row">
        <div className="col-md-4">
          <div className="card">
            <img src="img/Orionty.jpg" className="card-img-top" alt="Orionty" />
            <div className="card-body">
              <h5 className="card-title">Okyere Amponsah-Kwatiah</h5>
              <p className="card-text">Role: Full Stack Engineer <br />
              <Link to='https://www.esurde.com' className="card-text text-decoration-none " target='_blank'>Esurde CEO (www.esurde.com)</Link>
              </p>
              <div className="d-flex  mt-3">
            <Link to='https://www.linkedin.com/in/okyere-amponsah-kwatia-141b761a4/'target='_blank' className='text-decoration-none text-dark'><FaLinkedin  className='fs-3 mx-2'/></Link>
            <Link to='https://web.facebook.com/okyere.amponsah.9/' className='text-decoration-none text-primary' target='_blank'> <FaFacebook  className='fs-3 mx-2'/></Link>
            <Link to='https://twitter.com/OriontyPrincip2' className='text-decoration-none text-primary' target='_blank'><FaTwitter  className='fs-3 mx-2'/></Link>
            <Link to='https://orionty.esurde.com' className='text-decoration-none text-success' target='_blank'>  <FaUserAlt  className='fs-3 mx-2'/></Link>
            <Link to='https://www.instagram.com/oriontyprinciples/?hl=en' className='text-decoration-none text-danger' target='_blank'><FaInstagram  className='fs-3 mx-2'/></Link>
           
          </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-4">
          <div className="card">
            <img src="/path-to-team-member-image-2.jpg" className="card-img-top" alt="Team Member 2" />
            <div className="card-body">
              <h5 className="card-title">Team Member 2</h5>
              <p className="card-text">Role: Designer</p>
            </div>
          </div>
        </div> */}
        {/* Add more team members as needed */}
      </div>
    </div>

    <hr />
    <br />
    <Footer />
   </section>
  );
};

export default AboutPage;
