import React from 'react';
import Author from '../components/Author';
import AuthorsNavBar from '../quotes/AuthorsNavBar';
import AuthorsBackground from './AuthorsBackground';
import Footer from './Footer';

const authorsData = [
  {
    name: 'Dr. Kofi Amponsah-Kwatiah',
    image: 'img/Dr Amponsah.jpg', // Replace with the author's image path
    bio: 'Dr. Kofi Amponsah-Kwatiah is a professional valuation and estate surveyor with 22 years of experience in estate, project and facilities management. He is a member of the Ghana Institution of Surveyors (MGhIs). His doctorate degree is in management. He is the founder of TOTAL LIFE EVANGELISM FOUNDATION (TOLEF), an evangelical platform for Bible studies and life management. Dr.Amponsah-Kwatiah is a lay preacher and life management speaker whose presentations motivate and guide people through change processes. Real life issues are fundamental to his teachings on any subject he speaks on.',
    // Add social media links or website here
  },
 
  
];

const MeetTheAuthors = () => {
  return (
   <div>
<div className='authors-bg'>
<AuthorsNavBar />
<AuthorsBackground />
</div>
<div className="container mt-5">
      <h2 className='text-center mt-5 mb-5'>Meet the Authors</h2>
      <hr />
      <br />
      <div className="row">
        {authorsData.map((author, index) => (
          <div key={index} className="col-md-4 mb-5 ">
            <Author author={author} />
          </div>
        ))}
      </div>
    </div>
<hr />
<br />
<Footer />

   </div>
  );
};

export default MeetTheAuthors;
