import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import quotes from '../quotes/quotes.json';
import ShareButtons from './ShareButtons';
import QuoteBackground from './QuoteBackground';
import Footer from './Footer';
import IndividualNavBar from '../quotes/IndividualNavBar';

const IndividualQuotePage = () => {
  const { quoteId } = useParams();
  const [displayImages, setDisplayImages] = useState(false);

  const toggleDisplay = () => {
    setDisplayImages(!displayImages);
  };

  useEffect(() => {
    // Fetch the quote data based on quoteId
    // Simulate fetching from your Data array
    const selectedQuote = quotes.find(
      (quote) => quote.id === parseInt(quoteId, 10)
    );
    if (!selectedQuote) {
      console.error('Quote not found');
    }
  }, [quoteId]);

  return (
    <div>
      <div className='quote-bg'>
        <IndividualNavBar />
        <QuoteBackground />
      </div>
      <div className="container mt-5">
        <div className="mb-3">
          <button className="btn btn-dark" onClick={toggleDisplay}>
            {displayImages ? 'Switch to Text' : 'Switch to Image'}
          </button>
        </div>
        <div className="row">
          <div className="col-md-3 mb-4">
            <div className="card shadow-lg bg-body rounded">
              {displayImages ? (
                <div>
                  <img
                    src={quotes[quoteId - 1]?.image}
                    className="card-img-top"
                    alt=" "
                  />
                  <ShareButtons quoteText={quotes[quoteId - 1]?.text} />
                </div>
              ) : (
                <div className="card-body">
                  <p className="card-text">{quotes[quoteId - 1]?.text}</p>
                  <ShareButtons quoteText={quotes[quoteId - 1]?.text} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <br />
      <Footer />
    </div>
  );
};

export default IndividualQuotePage;
