import React from 'react';
import {FaFacebook,FaLinkedin,FaTwitter,FaInstagram} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {AiTwotoneMail} from 'react-icons/ai'




const Author = ({ author }) => {
  return (
    <div className="card bg-body shadow-lg rounded-3">
      <img src={author.image} className="card-img-top" alt={`Author ${author.name}`} />
      <div className="card-body">
        <h5 className="card-title">{author.name}</h5>
        <p className="card-text">{author.bio}</p>
        {/* Add links to the author's social media profiles or website here */}
        <div className="d-flex ">
            <Link to='#' className='text-decoration-none text-primary'> <FaFacebook  className='fs-3 mx-2'/></Link>
            <Link to='#' className='text-decoration-none text-dark'><FaLinkedin  className='fs-3 mx-2'/></Link>
            <Link to='#' className='text-decoration-none text-primary'><FaTwitter  className='fs-3 mx-2'/></Link>
            <Link to='#' className='text-decoration-none text-dark'>  <AiTwotoneMail  className='fs-3 mx-2'/></Link>
            <Link to='#' className='text-decoration-none text-danger'><FaInstagram  className='fs-3 mx-2'/></Link>
           
          </div>
      </div>
    </div>
  );
};

export default Author;
