import React, { Component } from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import QuotePage from './components/QuotePage';
import TopicsPage from './components/TopicsPage';
import MeetTheAuthors from './components/AuthorsPage';
import ContactPage from './components/ContactPage';
import AboutPage from './components/AboutPage';
import IndividualQuotePage from './components/IndividualQuotePage';
import GetInspired from './components/GetIinspired';
import LovePage from './topics/lovePage';
import InspirationPage from './topics/InspirationPage';
import SuccessPage from './topics/Success';
import FriendshipPage from './topics/FriendshipPage';
import HealthPage from './topics/HealthPage';
import CreativityPage from './topics/Creativity';
import HappinessPage from './topics/Happiness';
import WisdomPage from './topics/WisdomPage';
import PositivityPage from './topics/Positivity';
import AngerPage from './topics/AngerPage';
import ForgivenessPage from './topics/ForgivenssPage';
import LeadershipPage from './topics/Leadership';
import GrowthPage from './topics/Growth';
import NaturePage from './topics/Nature';
import HumorPage from './topics/Humor';
import ParentingPage from './topics/Parenting';
import GratitudePage from './topics/Gratitude';
import KindnessPage from './topics/Kindness';
import EducationPage from './topics/Education';
import CouragePage from './topics/Courage';
import InnovationPage from './topics/Innovation';
import BusinessPage from './topics/Business';
import FamilyPage from './topics/Family';
import FearPage from './topics/Fear';
import TimePage from './topics/Time';
import WorkPage from './topics/Work';
import BuyBook from './components/BuyBook';

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
      <Routes>
      <Route path='/'  element = {<Home />}></Route>
      <Route path='/quotes'  element = {<QuotePage />}></Route>
      <Route path='/topics'  element = {<TopicsPage />}></Route>
      <Route path='/authors'  element = {<MeetTheAuthors />}></Route>
      <Route path='/contact-us'  element = {<ContactPage />}></Route>
      <Route path='/about'  element = {<AboutPage />}></Route>
      <Route path='/quotes/:quoteId'  element = {<IndividualQuotePage />}></Route>
      <Route path='/get-inspired'  element = {<GetInspired />}></Route>
      <Route path='/topics/love'  element = {<LovePage />}></Route>
      <Route path='/topics/success'  element = {<SuccessPage />}></Route>
      <Route path='/topics/friendship'  element = {<FriendshipPage />}></Route>
      <Route path='/topics/health'  element = {<HealthPage />}></Route>
      <Route path='/topics/creativity'  element = {<CreativityPage />}></Route>
      <Route path='/topics/happiness'  element = {<HappinessPage />}></Route>
      <Route path='/topics/wisdom'  element = {<WisdomPage />}></Route>
      <Route path='/topics/positivity'  element = {<PositivityPage />}></Route>
      <Route path='/topics/anger'  element = {<AngerPage />}></Route>
      <Route path='/topics/forgiveness'  element = {<ForgivenessPage />}></Route>
      <Route path='/topics/leadership'  element = {<LeadershipPage />}></Route>
      <Route path='/topics/growth'  element = {<GrowthPage />}></Route>
      <Route path='/topics/nature'  element = {<NaturePage />}></Route>
      <Route path='/topics/humor'  element = {<HumorPage />}></Route>
      <Route path='/topics/parenting'  element = {<ParentingPage />}></Route>
      <Route path='/topics/gratitude'  element = {<GratitudePage />}></Route>
      <Route path='/topics/kindness'  element = {<KindnessPage />}></Route>
      <Route path='/topics/education'  element = {<EducationPage />}></Route>
      <Route path='/topics/courage'  element = {<CouragePage />}></Route>
      <Route path='/topics/innovation'  element = {<InnovationPage />}></Route>
      <Route path='/topics/business'  element = {<BusinessPage />}></Route>
      <Route path='/topics/family'  element = {<FamilyPage />}></Route>
      <Route path='/topics/fear'  element = {<FearPage />}></Route>
      <Route path='/topics/time'  element = {<TimePage />}></Route>
      <Route path='/topics/work'  element = {<WorkPage />}></Route>
      <Route path='/topics/inspiration'  element = {<InspirationPage />}></Route>
      <Route path='/why-they-achieve-little'  element = {<BuyBook />}></Route>
    

      </Routes>
      
      
      </BrowserRouter>
    )
  }
}
