import React from "react";
import axios from "axios";
import ContactNavBar from "../quotes/ContactNavBar";
import ContactBackground from "./ContactBackground";
import Footer from "./Footer";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      PageEmail: "",
      message: "",
    };
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ ContactEmail: event.target.value });
  }

  onMsgChange(event) {
    this.setState({ message: event.target.value });
  }

  submitEmail(e) {
    e.preventDefault();
    axios({
      method: "POST",
      url: "http://localhost:3001/send/mail",
      data: this.state,
    }).then((response) => {
      if (response.data.status === "success") {
        alert("Message Sent.");
        this.resetForm();
      } else if (response.data.status === "fail") {
        alert("Message failed to send.");
      }
      window.location.reload();
    });
    
  }

  resetForm() {
    this.setState({ name: "", ContactEmail: "", message: "" });
  }


  render() {
    return (
      <section>
        <div className="contact-bg">
        <ContactNavBar />
        <ContactBackground />
       </div>
       <br />
       <br />
      <div className="container w-75">
      <div>
          <p id="animation">
            Please do not to hesitate to contact us directly.
          </p>
        </div>

        <div class="container shadow-lg p-3 mb-5 bg-body rounded">
          <p>
            We are grateful that you came to our website. Please use this form
            to provide feedback regarding your visit, the site's content, or to
            report any broken links. Your suggestions and comments are
            appreciated.
          </p>
        </div>
        <br />
      </div>
      <hr />
      <br />
        <div className=" text-light container">
            
       
        <section className="container text-dark  w-75">
          <div class="container shadow-lg p-3 mb-5  rounded">
            <h1 className="container text-center text-dark">Contact Us</h1>
            <form onSubmit={this.submitEmail.bind(this)} method="POST">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control inputbg"
                  name="name"
                  onChange={this.onNameChange.bind(this)}
                  value={this.state.name}
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div class="form-group">
                <label for="email">Email address</label>
                <input
                  type="email"
                  class="form-control inputbg"
                  name="ContactEmail"
                  placeholder="Enter your email"
                  required
                  value={this.state.contact_email}
                  onChange={this.onEmailChange.bind(this)}
                />
              </div>
              <div class="form-group">
                <label for="message">Message</label>
                <textarea
                  class="form-control inputbg"
                  name="message"
                  rows="3"
                  required
                  value={this.state.message}
                  onChange={this.onMsgChange.bind(this)}
                ></textarea>
              </div>
              <button class="btn btn-dark mt-4 btnbg text-center" type="submit">
                Submit
              </button>
            </form>
          </div>
         
        </section>
       


        
      </div>
      <hr />
          <br />
          <Footer />
      </section>
    );
  }
}

export default ContactPage;
