import React, { useState } from 'react';
import bookImage from '../book-image.jpg'; // Replace with the actual path to your book image
import {FaLongArrowAltLeft} from 'react-icons/fa'
import { Link } from 'react-router-dom';

const BuyBook = () => {
  const [quantity, setQuantity] = useState(1);
  const [selectedOption, setSelectedOption] = useState('soft'); // Default to soft copy

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleBuyNow = () => {
    // Calculate the total cost based on quantity and selected option
    const baseCost = selectedOption === 'soft' ? 50 : 50;
    const totalCost = quantity * baseCost;

    // Display the selected quantity and total cost
    alert(`You have selected ${quantity} copies of ${selectedOption} copy. Total cost: GHS ${totalCost}`);
  };

  return (
    <div className="container-fluid">
      <div className="container">
        <p><FaLongArrowAltLeft /> <Link to='/get-inspired' className='text-decoration-none'>Back</Link> </p>
        <h2>Payment Information</h2>
        <p className="mb-4">To purchase a copy of our book, please follow the steps below:</p>
        <ol>
          <li>Select the quantity of copies you wish to purchase.</li>
          <li>Choose the type of copy you prefer, either a "Soft Copy" (digital download) for GHS 50 or a "Hard Copy" (physical book) for GHS 60.</li>
          <li>Click the "Buy Now" button to proceed to payment.</li>
          <li>You will be redirected to our secure payment gateway to complete the transaction.</li>
          <li>Enter your payment details and confirm your purchase.</li>
        </ol>
        <p className="mt-4">
          Once the payment is successfully processed, you will receive a confirmation email with details on how to access your digital copy (if applicable) and information about the shipping of physical copies. Thank you for choosing our book, and happy reading!
        </p>
      </div>
      <hr />
      <div className="text-center">
        <div>
          <img src={bookImage} className="mb-5" alt="Book Cover" style={{ maxWidth: '200px' }} />
        </div>
        <div>
          <label className="mt-2">
            Quantity:
            <input type="number" className="form-control mt-2" value={quantity} onChange={handleQuantityChange} />
          </label>
        </div>
        <div className="m-1 p-1">
          <label>
            Copy Type:
          </label>
          <div>
            <label>
              <input
                type="radio"
                value="soft"
                checked={selectedOption === 'soft'}
                onChange={handleOptionChange}
              />
              Soft Copy (GHS 50)
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                value="hard"
                checked={selectedOption === 'hard'}
                onChange={handleOptionChange}
              />
              Hard Copy (GHS 50)
            </label>
          </div>
        </div>
        <button onClick={handleBuyNow} className="btn btn-primary mb-5">Buy Now</button>
        <hr />
      </div>
    </div>
  );
};

export default BuyBook;
