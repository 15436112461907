import React from 'react';
import {Link} from 'react-router-dom'
import {FaFacebook,FaLinkedin,FaTwitter,FaYoutube,FaInstagram} from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer mt-auto py-3 bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mb-5">
            <h5>Stay Connected</h5>
            <p>Follow us on social media for daily inspiration.</p>
            
            <div className="d-flex">
            <Link to='#' className='text-decoration-none text-light'> <FaFacebook  className='fs-3 mx-2'/></Link>
            <Link to='#' className='text-decoration-none text-light'><FaLinkedin  className='fs-3 mx-2'/></Link>
            <Link to='#' className='text-decoration-none text-light'><FaTwitter  className='fs-3 mx-2'/></Link>
            <Link to='#' className='text-decoration-none text-light'>  <FaYoutube  className='fs-3 mx-2'/></Link>
            <Link to='#' className='text-decoration-none text-light'><FaInstagram  className='fs-3 mx-2'/></Link>
           
          </div>
          </div>
          <div className="col-md-6 text-md-right">
            <h5>Contact Us</h5>
            <address>
              Email: your@email.com<br />
              Phone: (123) 456-7890<br />
              Address: 123 Main St, City, Country
            </address>
          </div>
        </div>
        <hr />
        <div className="text-center mt-3">
          &copy; {new Date().getFullYear()} KAK Quote || All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
